<template>
    <div class="wrapper">
        <aside class="menu">
            <ul>
                <li
                    v-for="tab in tabs"
                    :key="tab.key"
                    :class="{
                        active: $router.currentRoute.meta.tab
                            ? $router.currentRoute.meta.tab.includes(tab.key)
                            : false,
                    }"
                    @click="() => navigateTo(tab.route)"
                >
                    <span>{{ tab.label }}</span>
                    <svgicon name="triangle" class="triangle" />
                </li>
            </ul>
        </aside>
        <transition name="slide" mode="out-in">
            <router-view />
        </transition>
    </div>
</template>

<script>
export default {
    name: 'management',
    data() {
        return {
            tabs: [
                {
                    label: 'Invoices',
                    key: '_invoices',
                    route: 'management_invoices',
                    path: '/manage/invoices',
                    enabled: this.$store.getters.is_xero_enabled,
                },
                {
                    label: 'Clients',
                    key: '_clients',
                    route: 'management_clients',
                    path: '/manage/clients',
                    enabled: true,
                },
                {
                    label: 'Projects',
                    key: '_projects',
                    route: 'management_projects',
                    path: '/manage/projects',
                    enabled: true,
                },
                {
                    label: 'Payment plans',
                    key: '_paymentplans',
                    route: 'payment_plans',
                    path: '/manage/paymentplans',
                    enabled: this.$store.getters.is_xero_enabled,
                },
                {
                    label: 'Estimate templates',
                    key: '_estimates',
                    route: 'management_estimates',
                    path: '/manage/estimates',
                    enabled: true,
                },
                {
                    label: 'Document templates',
                    key: '_templates',
                    route: 'management_templates',
                    path: '/manage/templates',
                    enabled: true,
                },
                {
                    label: 'Users',
                    key: '_users',
                    route: 'management_users',
                    path: '/manage/users',
                    enabled: true,
                },
                {
                    label: 'Reports',
                    key: '_reports',
                    route: 'reports',
                    path: '/reports',
                    enabled: true,
                },
            ].filter((x) => x.enabled),
            showMenu: false,
        };
    },
    methods: {
        navigateTo(route) {
            if (this.$route.name === route) return;
            this.$router
                .push({
                    name: route,
                })
                .catch((e) => {
                    console.log('Route error', e);
                });
        },
        toggleMenu() {
            this.showMenu = !this.showMenu;
        },
    },
};
</script>

<style lang="scss" scoped>
.wrapper {
    margin-top: -10px;
    margin-bottom: -10px;
    margin-left: -10px;
    width: calc(100% + 20px);
    flex: 1;
    display: flex;
    flex-direction: row;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;

    @media screen and (max-width: 992px) {
        flex-direction: column;
    }

    aside {
        background-color: rgba($border-grey, 0.1);
        border-right: 1px solid $border-grey;

        ul {
            padding: 10px 20px 10px 10px;
            margin: 0;
            list-style: none;
            width: 250px;

            @media screen and (max-width: 992px) {
                width: initial;
                padding: 10px;
            }

            li {
                height: 20px;
                padding: 20px;
                border-bottom: 1px solid rgba($border-grey, 0.2);
                cursor: pointer;
                display: flex;
                flex-direction: row;

                span {
                    flex: 1;
                }

                svg {
                    height: 20px;
                    width: 6px;
                    display: none;
                    opacity: 0.1;
                }

                &.active {
                    svg {
                        display: block;
                        opacity: 0.3;
                    }
                }

                &:hover {
                    svg {
                        display: block;
                    }
                }

                @media screen and (max-width: 992px) {
                    height: 40px;
                    padding: 2px 10px;
                    border: none;
                    align-items: center;

                    span {
                        font-size: 14px;
                        font-weight: 500;
                    }

                    svg {
                        display: none !important;
                    }

                    &.active {
                        span {
                            color: $blue;
                        }
                    }
                }
            }
        }
    }

    .slide {
        &-enter-active,
        &-leave-active {
            transition: opacity 0.2s ease-in-out, transform 0.3s ease-in-out;
        }

        &-enter,
        &-leave-to {
            opacity: 0;
            transform: translateX(5px);
        }
    }
}
</style>
